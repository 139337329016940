import { trpc, type RouterOutput } from '@/lib/providers/trpc';
import { useTranslation } from 'react-i18next';
import { TypographyH1, TypographyH2, TypographyMuted, TypographySmall } from '../ui/typography';
import { useDateFns } from '@/hooks/useDateFns';
import { PrintFormViewer } from '../form-viewers/print-form-viewer';
import { DeviationListSection, ProcessedFormSection } from '@/pages/app-main/inspections/inspections-details-components';
import { Button } from '../ui/button';
import { Portal } from '@radix-ui/react-portal';
import { DownloadIcon } from 'lucide-react';
import { useTenantIdSafe } from '@/hooks/useTenant';

interface InspectionDataProps {
	inspection: RouterOutput['tenantInspectionsById'];
	buttonText?: string;
}

const InfoItem = ({ label, value }: { label: string; value: string }) => (
	<div className="flex flex-col gap-y-2">
		<TypographyMuted className="text-sm font-medium text-muted-foreground">{label}</TypographyMuted>
		<TypographySmall className="text-base">{value}</TypographySmall>
	</div>
);

export function GenerateInspectionPdfContent({ inspection, buttonText }: InspectionDataProps) {
	const { t } = useTranslation();
	const { format } = useDateFns();
	const tenantId = useTenantIdSafe();

	const exportMutation = trpc.tenantInspectionsExport.useMutation({
		onSuccess(data) {
			const link = document.createElement('a');
			link.href = data.content;
			link.download = data.fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
	});

	return (
		<Button onClick={() => exportMutation.mutate({ inspectionId: inspection.id, tenantId })} isLoading={exportMutation.isLoading}>
			{!exportMutation.isLoading && <DownloadIcon className="size-4" />}
			{buttonText ?? t('export_pdf')}
			<Portal>
				<div id="report" className="hidden print:block">
					<div className="flex min-h-screen flex-col items-center justify-center p-8 print:break-after-page">
						<img src={`/api/rest/tenant-logo/${tenantId}`} alt="TIMP Logo" className="mb-8 h-auto w-48" />
						<TypographyH1 className="mb-4 text-3xl font-bold">{t('inspection_summary_report')}</TypographyH1>
						<TypographyH2 className="mb-8 text-xl text-muted-foreground">{inspection.formTemplateName}</TypographyH2>

						<div className="w-full max-w-3xl rounded-md border bg-white p-8">
							<div className="grid grid-cols-2 gap-y-8">
								<InfoItem label={t('supplier')} value={inspection.supplierName || '-'} />

								<InfoItem label={t('status')} value={t(inspection.status)} />

								<InfoItem label={t('inspector')} value={inspection.createdByInspectorName || '-'} />
								<InfoItem label={t('created_at')} value={format(new Date(inspection.createdAt), 'PPP')} />

								<InfoItem label={t('processed_by')} value={inspection.processedByName || '-'} />
								<InfoItem label={t('processed_at')} value={inspection.processedAt ? format(new Date(inspection.processedAt), 'PPP') : '-'} />
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-y-8 print:break-after-page">
						<ProcessedFormSection inspection={inspection} />

						<DeviationListSection inspection={inspection} />
					</div>
					<PrintFormViewer form={inspection.formContent} />
				</div>
			</Portal>
		</Button>
	);
}
