import { useTranslation } from "react-i18next";
import type { RouterOutput } from "@/lib/providers/trpc";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { TypographyLabel, TypographyMuted } from "@/components/ui/typography";
import { LinkButton } from "@/components/buttons/link-button";
import { useDateFns } from "@/hooks/useDateFns";
import { DeviationSeverityIconLabel } from "@/components/labels/deviation-severity-icon-label";

interface SectionProps {
	inspection: RouterOutput["tenantInspectionsById"];
}
export function ProcessedFormSection({ inspection }: SectionProps) {
	const tenantId = useTenantIdSafe();
	const { t } = useTranslation();
	const { format } = useDateFns();

	return (
		<div className="flex flex-col gap-y-8 overflow-y-auto">
			<div className="flex gap-x-4">
				<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t("supplier")}</TypographyLabel>

					{inspection.supplierId ? (
						<>
							<LinkButton
								variant="link"
								className="inline p-0 text-blue-600 print:hidden"
								target="_blank"
								to={`/tenants/${tenantId}/suppliers/${inspection.supplierId}`}
							>
								{inspection.supplierName}
							</LinkButton>
							<TypographyMuted className="hidden print:block">
								{inspection.supplierName}
							</TypographyMuted>
						</>
					) : (
						<TypographyMuted>-</TypographyMuted>
					)}
				</div>

				<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t("department")}</TypographyLabel>
					<TypographyMuted>{inspection.departmentName ?? "-"}</TypographyMuted>
				</div>
			</div>

			<div className="flex gap-x-4">
				<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t("project")}</TypographyLabel>
					<TypographyMuted>{inspection.projectName ?? "-"}</TypographyMuted>
				</div>

				<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t("location")}</TypographyLabel>
					<TypographyMuted>{inspection.locationName ?? "-"}</TypographyMuted>
				</div>
			</div>

			<div className="flex gap-x-4">
				<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t("equipment")}</TypographyLabel>
					<TypographyMuted>{inspection.equipmentName ?? "-"}</TypographyMuted>
				</div>

				<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t("status")}</TypographyLabel>
					<TypographyMuted>{t(inspection.status)}</TypographyMuted>
				</div>
			</div>

			<div className="flex gap-x-4">
				<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t("INSPECTOR")}</TypographyLabel>
					<TypographyMuted>
						{inspection.createdByInspectorName} at{" "}
						{format(new Date(inspection.createdAt), "Pp")}
					</TypographyMuted>
				</div>
				<div className="flex basis-1/2 flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t("processed_by")}</TypographyLabel>
					<TypographyMuted>
						{inspection.processedByName ?? "-"}
						{inspection.processedAt && (
							<span> at {format(new Date(inspection.processedAt), "Pp")}</span>
						)}
					</TypographyMuted>
				</div>
			</div>
			<div className="flex gap-x-4">
				<div className="flex min-h-24 basis-full flex-col gap-y-2 rounded-md bg-muted p-3">
					<TypographyLabel>{t("comment")}</TypographyLabel>
					<TypographyMuted>{inspection.comment}</TypographyMuted>
				</div>
			</div>
		</div>
	);
}

export function DeviationListSection({ inspection }: SectionProps) {
	const { t } = useTranslation();
	return (
		<div className="flex flex-col gap-y-4 overflow-x-auto">
			<div className="flex gap-x-2">
				<TypographyLabel>{t("deviations")}</TypographyLabel>
			</div>
			{inspection.deviations.length > 0 ? (
				<ul className="flex flex-col gap-y-2">
					{inspection.deviations.map((deviation) => (
						<li
							key={deviation.id}
							className="flex flex-col gap-y-2 rounded-md bg-muted p-3"
						>
							<DeviationSeverityIconLabel severity={deviation.severity} />
							<TypographyMuted>{deviation.description}</TypographyMuted>
						</li>
					))}
				</ul>
			) : (
				<TypographyMuted>{t("no_deviations")}</TypographyMuted>
			)}
		</div>
	);
}
